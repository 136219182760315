.thank-you-container {
    font-weight: bold;
    font-size: 28px;
}
.request-received-title {
    font-weight: bold;
    font-size: 18px;
}
.request-received-paragraph {
    width: 60%;
}
.status-end {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.provisioning-publication-status-container {
    @media (max-width: 767px) {
        width: 100%;
    }
    @media (min-width: 767px) {
        width: 50%;
    }
}
