.projectName {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin: 2%;
    overflow: visible;
    line-break: anywhere;

    @media (max-width: 991px) {
        font-size: 16px;
    }
}

.startChatroom {
    overflow: visible;
    white-space: nowrap;
    border: none;
    background: none;
    text-decoration: none !important;
}

.chat-container {
    @media (max-width: 768px) {
        width: 30px !important;
    }

    @media (min-width: 768px) {
        width: 25px !important;
    }

    padding: 3px;
    margin-left: 10px;
}

.spaceRight {
    margin-right: 2%;

    button {
        margin: 2%;
    }

    a {
        padding-top: 3%;
    }
}

.projectCommandsButton {
    margin: 0px 4px 16px;
    padding: 13px 36px 13px 36px;
    border: 0px;
    height: 2rem !important;
    width: 6rem !important;
}

.projectCommandsButtonContained {
    border: 0px;
    padding: 19px 10px 19px 19.5px;
    margin: 0px;

    span {
        overflow: visible;
        white-space: nowrap;
    }
}

.projectCommandsIconButton {
    margin: 16px 4px 16px;
    padding: 19px;
    border: 0px;
}

.iframecontainer {
    margin: 26px 17px 26px 24px;
    width: 100%;
    height: 1133px;
    left: 24px;
    top: 154px;
    background: #252525;
}

.projectCommandsContainer {
    width: fit-content;
    margin-right: 20px;
}

.projectCommandsContainer-small {
    button {
        padding: 7px;
    }
}

.withIFrame {
    display: flex;
    flex-direction: row;
    padding: 26px;
}

.iframeContainer {
    display: block;
    width: 100%;
    height: 1133px;
    left: 24px;
    top: 154px;
    margin: 0px 17px 26px 0px;
    background: #252525;
}

.projectCommandsButtonContainer {
    margin: 16px 4px 16px 4px;
}

.hideAccordionsButton {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;

    a {
        color: black;
        text-decoration: none;
    }
}

.inputAccordion {
    background-color: #fafbfc;
}

.accordionContainer {
    margin-bottom: 4%;
}

.chip {
    justify-content: center !important;
    min-width: fit-content !important;
}

.chip-label {
    line-height: 1.3;
    white-space: nowrap;
}

.sidePanelControl {
    padding: 5px !important;
}

.container-gray {
    background-color: #f2f2f2;
    padding-bottom: 10px;
    padding-top: 10px;
}

.project-commands-col {
    padding: 10px;
}

.side-control-container {
    border-bottom: 2px solid white;
}

.button-icon-container {
    width: fit-content;
    height: fit-content;
}

.icon-button {
    width: 24px;
    height: 24px;
}

.more-action-icon-button {
    width: 24px;
    height: 24px;
}

.more-action-button {
    border-radius: 4px !important;
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

.start-stop-button {
    border-radius: 4px !important;
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
}
.set-timeout-container {
    cursor: pointer;
}
.timeout-input-container {
    margin: 0px 12px;
}
.more-action-dropdown {
    width: max-content;
}
.link-item {
    cursor: pointer;
}

.open-button-container {
    margin-top: 16px !important;
}
