.containerAccordion {
    display: flex;
    flex-direction: column;

    #accordion {
        margin: 8px;
    }

    box-shadow: 1px;
}

.small {
    width: 300px;
}

.accordion-body {
    padding: 8px;
}

.inputAccordion {
    background-color: #fafbfc;
}

.accordionBodySmall {
    padding: 6px;
}

.containerInputButton {
    display: flex;
    flex-direction: row;

    button {
        margin-right: 2%;
        margin-left: 2%;
    }

    button:hover {
        color: #fff;
    }

    .btnInputLocal {
        min-width: 6.2rem;
        color: #fff;
    }
}

.btnInput {
    margin-bottom: 5.5px;
}

.creatorDiv {
    .creator {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
    }
}

.accordion.accordion-background-active .accordion-header .accordion-button {
    z-index: 0 !important;
}

.accordion-header .accordion-button:not(.collapsed):after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='white' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E") !important;
}

.accordion-header .accordion-button.collapsed:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='hsl(141deg, 100%, 28.5%)' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E") !important;
}

.plus-container {
    width: 25px;
}

.margin-button-add-team {
    margin-top: 15px;
    margin-bottom: 5px;
}

.save-container {
    width: 25px;
}

.upload-container {
    width: 25px;
}

.action-item {
    margin-right: 24px !important;
}

.member-container {
    // width: 30%;

    input {
        width: 14rem !important;
        margin: 4px;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}

.info-metadata {
    width: 105px;
    background-color: #dee2e6;
    float: left;
    height: 40px;
}

.info-metadata th {
    text-transform: capitalize;
    padding-top: 6px;
    padding-left: 11px;
}

.info-value {
    background-color: #f8f9fa;
    height: 40px;
}

.info-value td {
    padding-left: 30px;
    padding-top: 6px;
}

.container-info-metadata {
    border-style: solid;
    border-color: #dee2e6;
    border-width: 2px;
    margin-bottom: 5px;
}

.hidden {
    visibility: hidden !important;
}

.creator-container-info-accordion {
    padding-left: 19px;
    padding-bottom: 20px;
}

.btn-save {
    margin-left: 12px;
    color: white !important;
}

.icon-edit {
    display: block !important;
    position: relative;
    top: -2px;
    width: 20px;
    height: 20px;
}

.input-loader {
    display: flex;
    align-items: center;

    &:after {
        content: '';
        border: 5px solid #f3f3f3;
        border-top: 5px solid #009138;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 0.8s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.project-loader-percentage {
    margin-left: 1rem;
}

a {
    font-size: 15px;
}
