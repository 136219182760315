.provisioning-ide-component {
    height: 750px;
    width: 100%;
    //background-color: black;
}

.unplugged-icon {
    width: 15rem;
    height: 15rem;
}
