.project-data-header-container {
    font-weight: bold;
    font-size: 18px;
}

.metadata-row-container {
    background-color: #fafbfc;
    border-color: white !important;
}
.table-container {
    background-color: white;
}
.lever {
    float: left !important;
}

.additional-metadata-text-container {
    font-weight: bold;
    font-size: 18px;
}
