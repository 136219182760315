.input-output-header-container {
    font-weight: bold;
    font-size: 18px;
}

.metadata-row-container {
    background-color: #fafbfc;
    border-color: white !important;
}
.table-container {
    background-color: white;
}
.lever {
    float: left !important;
}
.icon-container {
    border-radius: 100%;
}
.action-item {
    margin-right: 24px !important;
}
.chip-status-container {
    text-transform: uppercase;
}

.button-row {
    display: flex;
    flex-wrap: wrap;
}

.position-button-left {
    @media (min-width: 576px) {
        text-align: start;
        padding-left: 2%;
    }
    @media (max-width: 575px) {
        text-align: center;
        padding-bottom: 5px;
    }
}

.position-button-next {
    text-align: end !important;
    padding-right: 2% !important;
}

.position-button-right {
    @media (min-width: 576px) {
        text-align: end;
        padding-right: 2%;
    }
    @media (max-width: 575px) {
        text-align: center;
        padding-top: 5px;
    }
}

.position-detail-element {
    justify-content: start;
    padding-left: 24px !important;
}

.text-header-detail {
    font-weight: bold;
    color: black !important;
}

.width-element-header {
    max-width: min-content !important;
}

.text-header {
    overflow-wrap: anywhere;
}

.close-container {
    width: 30px;
}

.header-flex {
    flex-flow: wrap;
}

.status-running {
    background-color: #b95000 !important;
}
.table-container {
    div {
        .table-container {
            margin-bottom: 8%;
        }
    }
}
.warning-icon-container {
    background-color: red;
}
.warning-message-container {
    background-color: #feefef;
    box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.2);
    border: 1px solid #f48989;
    .error-container {
        color: #da1414;
        font-weight: bold;
    }
    .error-message {
        color: #da1414;
        font-weight: normal;
    }
}
.check-icon-container {
    margin: 6px 12px;
}

.width-provisioning-final-button {
    max-width: 300px;
    min-width: 250px;
}

.width-provisioning-button {
    max-width: 200px;
    min-width: 150px;
}

.provisioning-space {
    @media only screen and (min-width: 1357px) {
        width: 115px;
    }
    @media only screen and (min-width: 1177px) and (max-width: 1357px) {
        width: 80px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1177px) {
        width: 67px;
    }
    @media only screen and (max-width: 992px) and (min-width: 840px) {
        width: 110px;
    }
    @media only screen and (max-width: 840px) {
        width: 60px;
    }
}
.status-container-space {
    width: 74px;
}
.action-container-spaced {
    width: 207px;
}
