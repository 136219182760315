.label {
    height: 21px;
    left: 0px;
    right: 0px;
    top: -24px;
    font-style: normal;
    font-weight: bold;
    line-height: 202.35%;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #000;
    margin-bottom: 3px;
}

.old-info-paragraph {
    height: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 202.35%;
    color: #000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 10%;
}

.requiredSign {
    color: #dc3545 !important;
    margin-bottom: 0px;
}

.oldInfo {
    margin-left: 35px;
}

.select-container {
    border-bottom: 1px solid #dee2e6 !important;
    height: 40px !important;
}

.active {
    z-index: 0 !important;
}

.select-wrapper select {
    color: #000 !important;
    font-weight: normal !important;
    font-size: 15px;
}

.label-color {
    color: #009138 !important;
}

.form-group input,
.form-group optgroup,
.form-group textarea {
    color: #000 !important;
}

div.box {
    height: 200px;
    padding: 4px;
    border: 1px solid #eee;
    border-right: 0 solid;
    overflow: auto;
    margin-left: -10px;
}

.text-value {
    height: 200px;
}

.old-info-container {
    margin-left: 9px;
}

.data-project-clone {
    display: none;
}

@media (min-width: 768px) {
    div.margin-old {
        margin-top: -21px;
    }

    .data-project-clone {
        display: block;
    }

    .margin-old-description {
        margin-bottom: 0px;
    }
}

@media (max-width: 991px) {
    .old-additional-info {
        display: none;
    }
    .value-container {
        margin-bottom: -18px !important;
    }
}

.textarea-description-container {
    height: 200px !important;
    resize: none;
}

.additional-info,
.old-additional-info,
.project-clone,
.data-project-clone {
    font-size: 16px;
    color: #000 !important;
    margin-top: -14px !important;
}

.button-plus {
    border-radius: 20px !important;
    margin-top: -42px !important;
}

@media (max-width: 415px) {
    .additional-info {
        font-size: 12px !important;
    }
}

@media (max-width: 575px) {
    .button-plus {
        border-radius: 20px !important;
        margin-top: -37px !important;
    }
}

.modal-additional-info-container {
    .btn {
        .minus-container {
            display: none;
        }
    }

    .btn:focus[aria-expanded='true'] {
        .plus-container {
            display: none;
        }

        .minus-container {
            display: block;
        }
    }

    .btn:focus[aria-expanded='false'] {
        .minus-container {
            display: none;
        }

        .plus-container {
            display: block;
        }
    }
}

.info-metadata {
    width: 105px;
    background-color: #dee2e6;
    float: left;
    height: 40px;
}

.info-metadata th {
    text-transform: capitalize;
    padding-top: 6px;
    padding-left: 11px;
    color: #000;
}

.info-value {
    background-color: #f8f9fa;
    height: 40px;
}

.info-value td {
    padding-left: 30px;
    padding-top: 6px;
    color: #000;
}

.container-info-metadata {
    border-style: solid;
    border-color: #dee2e6;
    border-width: 2px;
    margin-bottom: 5px;
}

.metadata-list-header {
    color: #000;
    font-size: 15px;
    margin-left: 9px;
    margin-top: -15px;
}

.category-index {
    z-index: 3;
}
