.member-container {
    //width: 40%;
    display: flex;
    flex-direction: row;
    gap: 1;
    border: none !important ;

    flex-wrap: wrap;
    .form-control {
        border: 1px solid #f2f2f2 !important;
        height: 2rem !important;
        font-size: 15px !important;
        // height: auto !important;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
}

.wrap-member-container {
    flex-wrap: wrap;
}

.member-text-field {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;

    .input-container {
        border-bottom: none !important;
        height: 40px !important;
    }
}

.btn-save-margin {
    margin-top: 0.7rem !important;
}
