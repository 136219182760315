.select-container {
    border-bottom: 1px solid #dee2e6 !important;
    height: 40px !important;
}

.filter-input-container {
    margin-bottom: 2rem !important;
}

.requiredSign {
    color: #dc3545 !important;
}
.active {
    z-index: 0 !important;
}

.form-group {
    margin-bottom: 2rem !important;
}

.modal .modal-dialog .modal-content .modal-header h5,
.modal .modal-dialog .modal-content .modal-header .h5 {
    color: #000 !important;
    font-size: 16px !important;
}

#abstract::placeholder,
#value::placeholder {
    color: #000 !important;
}

.form-group input,
.form-group optgroup,
.form-group textarea {
    color: #000 !important;
}

.metadata {
    font-size: 15px !important;
}

.additional-info,
.old-additional-info {
    font-size: 16px;
    color: #000 !important;
    margin-top: -14px !important;
}

.button-plus {
    border-radius: 20px !important;
    margin-top: -42px !important;
}

@media (max-width: 415px) {
    .additional-info {
        font-size: 12px !important;
    }
}

@media (max-width: 575px) {
    .button-plus {
        border-radius: 20px !important;
        margin-top: -37px !important;
    }
}

.modal-additional-info-container {
    .btn {
        .minus-container {
            display: none;
        }
    }

    .btn:focus[aria-expanded='true'] {
        .plus-container {
            display: none;
        }

        .minus-container {
            display: block;
        }
    }

    .btn:focus[aria-expanded='false'] {
        .minus-container {
            display: none;
        }

        .plus-container {
            display: block;
        }
    }
}

.info-metadata {
    width: 105px;
    background-color: #dee2e6;
    float: left;
    height: 40px;
}

.info-metadata th {
    text-transform: capitalize;
    padding-top: 6px;
    padding-left: 11px;
    color: #000;
}

.info-value {
    background-color: #f8f9fa;
    height: 40px;
}

.info-value td {
    padding-left: 30px;
    padding-top: 6px;
    color: #000;
}

.container-info-metadata {
    border-style: solid;
    border-color: #dee2e6;
    border-width: 2px;
    margin-bottom: 5px;
}

.metadata-list-header {
    color: #000;
    font-size: 15px;
    margin-left: 9px;
    margin-top: -15px;
}

.category-index {
    z-index: 3;
}

.keyword-container {
    align-items: center;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    color: #000;
    font-size: 15px !important;
}

.btn-remove {
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
}
