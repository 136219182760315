.filter-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000;
}

.container {
    margin: 32px 0px 36px 24px;
}

.select-container {
    border-bottom: 1px solid #dee2e6 !important;
    height: 40px !important;
}

.filter-input-container {
    margin-bottom: 2rem !important;
}

.active {
    z-index: 0 !important;
}

.select-wrapper label {
    color: #009138 !important;
}

.label-color {
    color: #009138 !important;
    font-size: 15px !important;
}

@media (max-width: 767px) {
    .service-filterbar-center {
        margin-bottom: 36px;
    }
}
