body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
    padding: 6px 12px !important;
    width: fit-content;
    height: fit-content;
}

.btn-primary:hover {
    background-color: #009138 !important;
}

.btn-create-padding {
    padding: 6px 30px !important;
}

.btn-close-padding-header {
    padding: 3px 12px !important;
    white-space: nowrap !important;
}

.button-height {
    height: 42px;
    width: 150px !important;
}

.btn-open {
    padding: 7px 30px !important;
    margin-top: 1px !important;
}

.icon {
    width: 24px !important;
    height: 24px !important;
}

.accordion-header .accordion-button {
    padding: 7px 12px !important;
}

button.btn-primary:focus,
.btn-secondary:focus {
    box-shadow: none !important;
}

.btn-secondary:hover {
    background-color: #666666 !important;
}

input.form-control:focus {
    box-shadow: none !important;
    border-color: #f2f2f2 !important;
}

input:focus:not(.focus--mouse) {
    box-shadow: none !important;
    border-color: #f2f2f2 !important;
}

label.label-color:focus:not(.focus--mouse) {
    box-shadow: none !important;
    border-color: #f2f2f2 !important;
}

select.select-container:focus {
    box-shadow: none !important;
    border-color: #000 !important;
}

a.link:focus {
    box-shadow: none !important;
}

a.footer-link:focus:not(.focus--mouse) {
    border-color: #f2f2f2 !important;
}

a.dropdown-item:focus {
    box-shadow: none !important;
}

a.page-link:focus:not(.focus--mouse) {
    box-shadow: none !important;
}

button.btn-close:focus {
    box-shadow: none !important;
}

button.accordion-button:focus {
    box-shadow: none !important;
    border-color: #b1b1b3 !important;
}

button.chatroom-container:focus {
    box-shadow: none !important;
}

button.btn:focus {
    box-shadow: none !important;
    border: none !important;
}

button.startChatroom:focus {
    box-shadow: none !important;
}

textarea.textarea-container:focus {
    box-shadow: none !important;
    border-color: #f2f2f2 !important;
}

button.btn-primary:active,
button.btn-primary.active {
    box-shadow: inset 0 0 0 1px #009102, 0 0 0 0.25rem rgb(0 145 56 / 50%) !important;
}

button.btn-outline-primary:focus {
    box-shadow: inset 0 0 0 2px #009102 !important;
}

button.btn-outline-primary:active,
button.btn-outline-primary.active {
    box-shadow: inset 0 0 0 1px #009102, 0 0 0 0.25rem rgb(0 145 56 / 50%) !important;
}

button.btn-outline-secondary:focus {
    box-shadow: inset 0 0 0 2px #e6e9f2 !important;
}

button.btn-secondary:active,
button.btn-secondary.active {
    box-shadow: inset 0 0 0 1px #666666, 0 0 0 0.25rem rgba(146, 146, 146, 0.5) !important;
}

button.btn-outline-secondary:active,
button.btn-outline-secondary.active {
    box-shadow: inset 0 0 0 1px #666666, 0 0 0 0.25rem rgba(146, 146, 146, 0.5) !important;
}

a.footer-link:focus:not(.focus--mouse) {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
}

svg:focus:not(.focus--mouse) {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
}

body:not(.modal-open) {
    padding-right: 0px !important;
    overflow: auto !important;
}

.modal {
    overflow-y: hidden !important;
}

.modal-body {
    color: #000 !important;
}

.scrollable {
    overflow: auto !important;
}
