.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.app-view-container {
    height: fit-content;
    width: -webkit-fill-available;
    width: -moz-available;
}
@media screen and (min-width: 992px) {
    .app-view-container {
        height: fit-content;
    }
    .sidepanel-space {
        margin-left: 256px;
    }
}
.app-container {
    height: 100vh;
    font-size: 15px !important;
    font-weight: normal;
}

.btn-primary:disabled {
    background-color: gray !important;
}

.btn {
    font-size: 15px !important;
}
