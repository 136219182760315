#footer {
    bottom: 0;
    position: unset;
    z-index: 2;
}

#footerLogo {
    padding: 18px 25px 18px 24px;
}

.paragraph-color {
    color: white;
}
.footer-link,
.footer-link:hover {
    color: white;
}
.logo-container {
    @media (min-width: 768px) {
        margin-left: 24px;
    }
    @media (max-width: 768px) {
        margin-left: 0px;
    }
}

.button-close-text {
    margin: 0px !important;
    @media (min-width: 768px) {
        font-size: 15px;
        color: white;
    }
    @media (max-width: 768px) {
        font-size: 0px;
    }
}

.logo-section {
    padding-left: 35px !important;
}

.heading-title {
    color: #ffffff;
    font-family: 'Titillium Web', Sans-serif;
    font-weight: bold;
}

.divider {
    padding-top: 0px;
    padding-bottom: 0px;
}

.main-footer-list {
    display: flex;
    place-content: center;
    gap: 4vw;
    flex-wrap: wrap;
    padding: 2vw;
    justify-content: space-around;
    @media (min-width: 992px) {
        margin-left: 256px !important;
        width: -webkit-fill-available !important;
        width: -moz-available !important; /* WebKit-based browsers will ignore this. */
    }
    @media (max-width: 992px) {
        width: -webkit-fill-available !important;
        width: -moz-available !important; /* WebKit-based browsers will ignore this. */
    }
}

.justify-content-footer {
    justify-content: center;
}

.footer-element-position {
    @media (max-width: 768px) {
        text-align: -webkit-center !important;
    }
}

.footer-list-title {
    color: white;
    font-weight: bold !important;
    @media (min-width: 768px) {
        font-size: 20px !important;
    }
    @media (max-width: 768px) {
        font-size: 23px !important;
    }
}

.footer-list {
    padding-top: 1vh;
    padding-left: 0 !important;
}

.footer-li-style {
    list-style-type: none;
    color: white;
    font-style: italic;
    font-weight: normal;
    @media (min-width: 768px) {
        font-size: 17px !important;
    }
    @media (max-width: 768px) {
        font-size: 19px !important;
    }
}

.footer-hr {
    border-bottom: 1px solid white;
    @media (min-width: 768px) {
        width: 17vw;
    }
    @media (max-width: 768px) {
        width: 25vw;
    }
}

.footer-link {
    text-decoration: none;
    background-color: transparent;
}

.footer-social-wrapper {
    display: flex;
    gap: 20px;
}

.footer-social-circle {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 10px;
}

.footer-social-fa {
    color: #009138 !important;
}
