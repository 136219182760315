.start-page-top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metrics-main-container {
    background-color: #f2f2f2;
    //padding: 50px !important;
}

.resources-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.metrics-container {
    display: flex;
    gap: 4vw;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1vw 6vw !important;
}

.metrics-text {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: #009138;
    font-size: 18px;
}

.metrics-box {
    display: flex;
    padding: 45px;
    background: white;
    min-width: 300px;
    max-width: 300px;
    align-items: center;
    gap: 1vw;
    max-height: 12vh;
}

.header-title {
    text-align: center;
    background-color: #009138 !important;
    color: #ffffff !important;
    font-size: 41px !important;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-wrap: anywhere;
}

.home-header-title {
    text-align: center;
    color: #009138 !important;
    font-size: 41px !important;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding: 2vw 12vw;
    flex-direction: column;
    font-weight: bold;
}

.metrics-value {
    //font-weight: bold;
    font-size: 21px;
    color: black;
}

.home-title-container {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    padding: 2vw 12vw;
    margin-bottom: 1rem;
    align-items: center;
}

.header-title-container {
    display: flex;
    flex-direction: column;
    background-color: #009138 !important;
    padding: 2vw;
    // margin-bottom: 1rem;
    align-items: center;
}

.title-description {
    //max-width: 50%;
    text-align: center;
    font-size: 15px !important;
    color: #2e2e2e !important;
    line-height: 24px !important;
}

.secondary-title {
    text-align: center;
    max-width: 50%;
    color: #2e2e2e !important;
    font-size: 18px !important;
    line-height: 20px !important;
}

.banner {
    display: flex;
    flex-direction: row;
    padding: 4vw 0px;
    gap: 4vw;
    justify-content: center;
}

.banner-img {
    max-height: 100px !important;
    max-width: 800px !important;
    overflow: auto;
}

.banner-logo-img {
    max-width: 13vw;
    height: 100%;
}
