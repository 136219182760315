.button-input-minisite {
    color: #ffffff !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
    min-width: 222px;
}

.accordion-button-disabled {
    cursor: default !important;
    color: #d9d9d9 !important;
}

.disabled-link {
    pointer-events: none;
}

.run-service-container {
    padding-right: 30% !important;
    padding-left: 30% !important;
}

.btn-text-container {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    align-items: center;
    font-size: 15px;
}

.btn-failed-text-container {
    margin-right: 5rem;
    margin-left: 0.25rem;
    align-items: center;
    color: red;
    font-size: 15px;
}

.border-minisite {
    border: 2px solid hsl(225, 4.3062200957%, 89.55%) !important;
}

.btn-view-dataset {
    text-decoration: underline !important;
    z-index: 2;
    margin-left: 0.5rem;
    cursor: pointer;
}

.input-label-container {
    font-size: 16px;
    font-weight: bold;
}

.minisite-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 3;

    &:after {
        content: '';
        border: 5px solid #f3f3f3;
        border-top: 5px solid #009138;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 0.8s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.hide-accordion {
    display: none !important;
}

.loader-percentage {
    margin-right: 1rem;
    margin-left: 1rem;
}

.minisite-loader-container {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.failed-status-description {
    margin-right: 0.5rem;
    font-size: 15px !important;
}

.mail-notification-sent {
    margin-right: 0.5rem;
    font-size: 15px !important;
}
