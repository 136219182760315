.helper-container {
    color: #939393;
}

.check-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.label-color {
    color: #009138 !important;
}

.form-check {
    margin-left: 24px;
}

.button-container {
    margin-bottom: 20px;
}

.response-button {
    @media (max-width: 767px) {
        margin-bottom: 16px;
        width: 100% !important;
    }
}

.inline-text {
    overflow: visible;
    display: inline !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.flex-flow {
    flex-flow: wrap !important;
}

.header-container {
    background-color: #f2f2f2;
    padding: 8px;
}

.projectName {
    font-size: 24px;
}
.link-response {
    @media (max-width: 767px) {
        margin-bottom: 49px;
    }
}

.message-container {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 15px !important;
}

.button-dashboard {
    text-align: center;
}

.requirements-label-color {
    color: #000 !important;
    font-size: 15px !important;
}

.button-container-padding {
    padding-top: 16rem !important;
}
