#btnCreateExecution {
    margin: 3px;
}

.info-metadata {
    width: 105px;
    background-color: #dee2e6;
    float: left;
    height: 40px;
}

.info-metadata th {
    text-transform: capitalize;
    padding-top: 6px;
    padding-left: 11px;
}

.info-value {
    background-color: #f8f9fa;
    height: 40px;
}

.info-value td {
    padding-left: 30px;
    padding-top: 6px;
}

.container-info-metadata {
    border-style: solid;
    border-color: #dee2e6;
    border-width: 2px;
    margin-bottom: 5px;
}
.iframeContainer {
    width: 100%;
    height: 1133px;
    left: 24px;
    top: 154px;
    background: #252525;
}
.hideAccordionsButton {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;

    a {
        color: black;
        text-decoration: none;
    }
}
.project-commands-col {
    padding: 10px;
}
.container-gray {
    background-color: #f2f2f2;
    padding-bottom: 10px;
    padding-top: 10px;
}
.accordionContainer {
    margin-bottom: 4%;
}
.accordion-container-closed-ide {
    .container-fluid {
        padding: 0px !important;
    }
}

.action-container-spacing {
    padding: 10px 0px;
}

#minisite-execution-btn {
    height: 36px !important;
}
