.tableTitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: 16px !important;
}

.table-icon-button {
    width: 24px;
    height: 24px;
}
.action-item {
    margin-right: 24px !important;
}

.service-table-container {
    div {
        .table-container {
            margin-bottom: 2%;
        }
    }
}

.chip-status-container {
    text-transform: uppercase;
    white-space: nowrap;
}

.description {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
}

.action-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
    width: 100%;
}

.link-item {
    cursor: pointer;
}
