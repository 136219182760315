.minisite-logo-container {
    height: 90px;
    width: 180px !important;
    margin-bottom: 1rem;
}

.background-container-no-image {
    background-color: #009138;
}

.background-container {
    background-repeat: no-repeat;
    background-size: cover;
    transition: background(0.3s, border-radius 0.3s, opacity 0.3s);
    width: 100%;
    height: 100%;
}

.minisite-title-header {
    color: #ffffff !important;
    mix-blend-mode: difference;
    display: flex;
    justify-content: center;
    background-color: transparent !important;
}

.minisite-title-no-bg {
    color: #ffffff !important;
    display: flex;
    justify-content: center;
}
