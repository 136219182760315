.steppers .steppers-header li {
    border-left: none !important;
    border-right: none !important;
}

.steppers .steppers-header li.active .steppers-number {
    color: hsl(0deg, 0%, 100%);
    background: hsl(141deg, 100%, 28.5%);
    border-color: hsl(141deg, 100%, 28.5%);
    padding-top: 2px;
    font-size: 1rem !important;
}

.step-container {
    width: min-content !important;
}

.stepper-divider {
    width: 20%;
    @media (min-width: 992px) {
        display: block;
    }
    @media (max-width: 992px) {
        display: none;
    }
}
.steppers-header {
    width: fit-content;
    @media (min-width: 992px) {
        margin: 0%;
    }
    @media (max-width: 992px) {
        margin: 18px 0px;
    }
}
.step-component-container {
    @media (min-width: 992px) {
        padding: 0px 10% !important;
    }
    min-height: 600px;
}
.container-gray {
    background-color: #f2f2f2;
    padding-bottom: 10px;
    padding-top: 10px;
}
.stepper-divider-confirmed {
    color: #009102;
    opacity: 1 !important;
}

.form-control:disabled,
.form-control[readonly] {
    cursor: not-allowed;
    font-weight: normal;
}

.steppers .steppers-header li {
    font-size: 15px !important;
}
