.iframe-container {
    height: 503px;
    left: 24px;
    top: 154px;
    background: #252525;
}
.accordion.accordion-background-active .accordion-header .accordion-button {
    z-index: 0 !important;
}
.accordion {
    overflow: hidden;
}
.accordion-item-container {
    .show {
        border: 1px solid hsl(225deg, 4.3062200957%, 70%) !important;
        height: 100%;
    }
}

.accordion-display-container {
    display: flex;
    overflow-x: auto;
    flex-direction: column;
}

.input-project-size {
    width: 100% !important;
    min-width: fit-content;
}

.log-size {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    container-type: inline-size;
    font-size: 15px;
}

.accordion-header .accordion-button:not(.collapsed):after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='white' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E") !important;
}
.accordion-header .accordion-button.collapsed:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='hsl(141deg, 100%, 28.5%)' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E") !important;
}

.output-accordion-body {
    max-height: 400px !important;
    overflow-y: auto;
}

.input-excution-buttons-container {
    gap: 3%;
    display: flex;
    flex-wrap: wrap;
}

.status-icon {
    display: flex;
    align-items: center;
}
