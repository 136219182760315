.title-container {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.filter-title {
    margin-bottom: 24px;
}

.tableTitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: 16px !important;
}

.more-action-button {
    border-radius: 4px !important;
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

.start-stop-button {
    border-radius: 4px !important;
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
}

.action-item {
    margin-right: 24px !important;
}

.action-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.5rem;
    padding-bottom: 1rem;
    width: 100%;
}

.project-table-container {
    div {
        .table-container {
            margin-bottom: 2%;
        }
    }
}

.td-programmingLanguage-size {
    width: 120px;
    padding-right: 15px;
    min-width: -webkit-fill-available;
}

.td-version-size {
    width: 180px;
    padding-right: 15px;
    min-width: -webkit-fill-available;
}

.td-name-size {
    width: 375px;
    padding-right: 15px;
    min-width: -webkit-fill-available;
}

.td-category-size {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    min-width: -webkit-fill-available;
    text-overflow: ellipsis;
    padding-right: 15px;
}

.td-published_at-size {
    width: 180px;
    padding-right: 15px;
    min-width: -webkit-fill-available;
}

.td-category-size-home {
    width: 225px;
    white-space: nowrap;
    overflow: hidden;
    min-width: -webkit-fill-available;
    text-overflow: ellipsis;
    padding-right: 15px;
}

.chip-status-container {
    text-transform: uppercase;
    white-space: nowrap;
}

.publish-container {
    border-radius: 4px !important;
}

.dropdown-menu {
    animation: none !important;
}

.btn-group {
    .btn {
        .arrow-up {
            display: none;
        }
    }

    .btn:focus[aria-expanded='true'] {
        .arrow-down {
            display: none;
        }

        .arrow-up {
            display: block;
        }
    }

    .btn:focus[aria-expanded='false'] {
        .arrow-up {
            display: none;
        }

        .arrow-down {
            display: block;
        }
    }
}
.back-icon {
    width: 20px;
}
.timeout-input-container {
    margin: 0px 12px;
}
.more-action-dropdown {
    width: max-content;
}

.description {
    padding: 1rem;
}

.creator-container {
    width: inherit;
    padding: 5px 0px;
}

.creator-label-container {
    color: black;
    font-weight: bold;
    align-self: center;
}

.creator-content-container {
    padding: 7px;
}

.creator-container-team {
    width: inherit;
    padding: 5px 5px 12px 0px;
}

.published-column {
    display: flex;
    padding-left: 15px;
}

.svg-container {
    width: calc(100% - 30px) !important;
}

.label-green {
    font-weight: bold;
    color: #009138 !important;
}

.dropdown-menu[data-popper-placement='top-end']:before {
    bottom: -4px;
    top: auto;
    left: 172px;
}

.dropdown-menu.show[data-popper-placement='bottom-start'] {
    position: absolute;
    inset: 0px 0px auto auto !important;
    margin: 0px;
    transform: translate(0.28125px, 38px) !important;
}

.dropdown-menu[data-popper-placement='bottom-start']:before {
    left: auto !important;
    right: 12px !important;
}
.behub-iframe {
    min-width: 100%;
    min-width: -moz-available;
    min-width: -webkit-fill-available;
    min-width: fill-available;
    min-height: 100%;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
}
