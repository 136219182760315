.webservice-container {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.chip-container {
    margin-left: 24px !important;
}

.inline-text {
    overflow: visible;
    display: inline !important;
}

.header-container {
    background-color: #f2f2f2;
    padding: 8px;
}
.chip {
    justify-content: center !important;
    min-width: fit-content !important;
}

.project-continer {
    min-width: 25px;
    max-width: 25px;
}

.align-center {
    align-self: center !important;
}

.startChatroom {
    overflow: visible;
    white-space: nowrap;
    border: none;
    background: none;
}
.chat-container {
    width: 25px;
    padding: 3px;
    margin-left: 10px;
}
.button-close {
    margin-right: 4.5px;
}
.created-by {
    font-size: 15px;
    color: #939393 !important;
}

.margin-execution {
    margin: 4px 0px !important;
}

@media (max-width: 667px) {
    .created-by {
        font-size: 15px;
        color: #939393 !important;
    }
    .webservice-container {
        font-size: 20px;
        align-items: center;
    }
    .project-name-container {
        font-size: 16px;
    }

    .button-execution-container {
        font-size: 10px !important;
    }
}

.margin-approve-execution {
    margin-left: 6px;
}

.status-chip-position {
    position: relative;
    right: 4px;
}
