.multi-select-option:hover {
    cursor: default;
    background-color: #cf4725 !important;
    color: white !important;
}

.margin-list {
    margin-bottom: 0px !important;
}

.link-list-text-style {
    line-height: 1.3rem !important;

    padding: 0px 12px !important;
}

.table-border {
    border: 1px solid;
}

.multi-select-option {
    font-size: 15px !important;
    color: #000 !important;
    line-height: 1.3rem !important;
    padding: 0px 12px !important;
}

.multi-select-dropdown-container {
    border: 1px solid black;
}

.selected-option {
    width: fit-content !important;
    span {
        color: #000 !important;
        background-color: rgb(230, 230, 230) !important;
    }
}

.placeholder-container {
    cursor: default;
    font-weight: normal !important;
    color: #000;
}

.multi-select-dropdown-container {
    border: 1px solid black;
}

@-moz-document url-prefix() {
    .backgroun-color-list {
        background-color: #f2f2f2 !important;
    }

    .multi-select-option {
        font-size: 15px !important;
        color: #000 !important;
        line-height: 1.2rem !important;
        padding: 2px 12px !important;
    }

    .margin-list {
        margin-top: 4px;
        margin-bottom: 4px !important;
    }

    .multi-select-option:hover {
        cursor: default;
        background-color: #dddbdb !important;
        color: #000 !important;
    }

    .multi-select-dropdown-container {
        border: 1px solid #dddbdb;
    }

    .multi-select-option:hover {
        cursor: default;
        background-color: #dddbdb !important;
        color: #000 !important;
    }
}

/* MacOS classes*/
.mac-multi-select-dropdown-container {
    border-radius: 10px !important;
}

.mac-multi-select-option {
    background-color: #696969 !important;
    color: white !important;
    font-weight: bold !important;
}

.first-item {
    border: 1px solid #696969 !important;
    border-radius: 6px 6px 0px 0px !important;
    background-color: #696969;
}

.last-item {
    border: 1px solid #696969 !important;
    border-radius: 0px 0px 6px 6px !important;
    background-color: #696969;
}

.mac-multi-select-option:hover {
    cursor: default;
    background-color: #6983ee !important;
    color: white !important;
    border-radius: 3px !important;
    width: 100% !important;
    display: inline-block;
}

.mac-rounded-border {
    border: 1px solid #696969 !important;
    border-radius: 6px !important;
    background-color: #696969 !important;
}

.windows-rounded-border {
    border: 1px solid #b6b6b6 !important;
    border-bottom: none;

    border-radius: 10px !important;
}

.windows-first-item {
    border-radius: 10px 10px 0px 0px !important;
    background-color: #696969;
}

.windows-last-item {
    border-radius: 0px 0px 10px 10px !important;
    background-color: #696969;
}

.windows-multi-select-option {
    font-size: 15px !important;
    color: #000 !important;
    line-height: 1.2rem !important;
    padding: 2px 12px !important;
}

.windows-multi-select-option:hover {
    cursor: default;
    background-color: #558bdd !important;
    color: white !important;
}

@-moz-document url-prefix() {
    .mac-multi-select-dropdown-container {
        border-radius: 10px !important;
    }

    .mac-multi-select-option {
        background-color: #dfdfdf !important;
        color: #000 !important;
    }

    .first-item {
        border: 1px solid #dfdfdf !important;
        border-radius: 6px 6px 0px 0px !important;
        background-color: #dfdfdf;
    }

    .last-item {
        border: 1px solid #dfdfdf !important;
        border-radius: 0px 0px 6px 6px !important;
        background-color: #dfdfdf;
    }

    .mac-multi-select-option:hover {
        cursor: default;
        background-color: #70a8fd !important;
        color: white !important;
        border-radius: 0px !important;
    }

    .mac-rounded-border {
        border: 1px solid #dfdfdf !important;
        border-radius: 6px !important;
        background-color: #dfdfdf !important;
    }

    .windows-rounded-border {
        border: 1px solid #949494 !important;
        border-bottom: 0px;

        border-radius: 3px !important;
    }

    .windows-first-item,
    .windows-last-item {
        border-radius: 3px !important;
        background-color: #dddbdb;
    }

    .windows-multi-select-option:hover {
        cursor: default;
        background-color: #dddbdb !important;
        color: #000 !important;
    }
}

.multi-select-dropdown-container::before {
    content: unset !important;
}
.popper {
    z-index: 10 !important;
    background-color: white !important;
    box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.2) !important;
    position: relative !important;
}

.popper-hidden {
    visibility: hidden;
    pointer-events: none;
}

div:focus,
div:focus:not(.focus--mouse) {
    border: none !important;
    box-shadow: none !important;
}
.selected-items-container,
selected-items-container:hover {
    text-decoration: none;
}

.select-multiple-border {
    border-bottom: 1px solid #dee2e6 !important;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 6.75px !important;
    padding-bottom: 6.75px !important;
    box-shadow: none !important;
}

.multiple-select-form-control {
    border: 0px !important;
    height: auto !important;
    font-size: 15px !important;
    text-overflow: ellipsis;
    container-type: inline-size;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal !important;
    color: #000 !important;
}

.white-space-line {
    white-space: pre-line;
}

.option-wrapper {
    width: 90% !important;
}

.option-wrapper-full-width {
    width: 100% !important;
    overflow-x: auto;
}

.dropdown-item-background {
    background-color: white;
}

.multi-select-container {
    height: 40px !important;
}

.multi-select-container:focus {
    height: 40px !important;
    border-color: #000 !important;
}

.multi-select-container .disabled {
    height: 40px !important;
    background-color: hsl(225deg, 32.2033898305%, 92.625%);
    opacity: 1;
}

.disabled {
    pointer-events: none !important;
}
